























import {
  defineComponent,
  ref,
  getCurrentInstance,
  Ref,
} from "@vue/composition-api";
import { Route, NavigationGuardNext } from "vue-router";
import Vue from "vue/types/umd";

export default defineComponent({
  layout: "default",
  setup(): Record<string, unknown> {
    const instance = getCurrentInstance();
    const login = () => instance?.proxy?.$microsoft?.doRedirectLogin();
    let initial: Ref<boolean> = ref(true);

    return {
      initial,
      login,
    };
  },
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    next(async (vm: Vue) => {
      const component = vm as { initial: boolean } & Vue;
      if (to.hash !== undefined && to.hash !== null && to.hash !== "") {
        component.initial = false;
        await vm?.$microsoft?.handleRedirectLoginResponse(to.hash);
        next({
          name: "index",
        });
      }
    });
  },
});
